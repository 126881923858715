// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets//images/copyIcon_normal.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets//images/copyIcon_hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button._icon {\n    background-color: transparent;\n    width: 19px;\n    height: 22px;\n    margin-right: 10px;\n}\n\n.icon_btn {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    width: 100%;\n    height: 100%;\n}\n\n.icon_btn:hover {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n.icon_btn_tooltip {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: -12px;\n    height: 20px;\n    width: 80px;\n    background-color: #D9D9D9;\n    border-radius: 6px;\n}\n\n.tooltip_txt {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/Functional/Button/IconButton/IconButton.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mDAA+D;IAC/D,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mDAA8D;AAClE;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".button._icon {\n    background-color: transparent;\n    width: 19px;\n    height: 22px;\n    margin-right: 10px;\n}\n\n.icon_btn {\n    background: url(../../../../assets//images/copyIcon_normal.svg);\n    width: 100%;\n    height: 100%;\n}\n\n.icon_btn:hover {\n    background: url(../../../../assets//images/copyIcon_hover.svg);\n}\n\n.icon_btn_tooltip {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: -12px;\n    height: 20px;\n    width: 80px;\n    background-color: #D9D9D9;\n    border-radius: 6px;\n}\n\n.tooltip_txt {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
